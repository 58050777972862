/// <reference types="@typescript/lib-dom" />

async function submitContactEmail() {
    let submitFormButton = (<HTMLButtonElement>document.getElementById("show_email_button"));

    if (submitFormButton.getAttribute("data-require-page-refresh") === "true") {
        window.location.reload();
        return;
    }

    submitFormButton.disabled = true;
    submitFormButton.classList.remove("hover:bg-stone-800");
    submitFormButton.innerText = "Loading ...";

    try {
        grecaptcha.ready(function () {
            grecaptcha.execute(
                '6LddOPIpAAAAAERf7eqOoCqiCPqU-XQ-NZXu0BtG',
                {action: 'get_contact_email'},
            ).then(showContactEmail);
        });
    } catch (error) {
        console.error("failed to show contact email", error);
        submitFormButton.disabled = false;
        submitFormButton.classList.add("hover:bg-stone-800");
        submitFormButton.innerText = "Click here to refresh the page and try again";
        submitFormButton.setAttribute("data-require-page-refresh", "true");
        return;
    }
}

window.submitContactEmail = submitContactEmail;

async function showContactEmail(token: string) {
    try {
        let response = await fetch("https://www.andyl.com/api/v1/get-contact-email", {
            method: "POST",
            body: JSON.stringify({"token": token})
        });

        let data = await response.json();

        if ("error" in data) {
            console.error("Error fetching contact email", data);
            document.getElementById("contact_email").innerHTML = `{error: ${data["error"]}}`;
            return;
        }

        if (!("email" in data) || !data["email"]) {
            console.error("Error fetching contact email", data);
            document.getElementById("contact_email").innerHTML = `[email not currently available]`;
            return;
        }

        const contact_email = data["email"];

        document.getElementById("contact_email").innerHTML = `<a href="mailto:${contact_email}"><u>${contact_email}</u></a>`;
    } catch (error) {
        console.error(error);
        document.getElementById("contact_email").innerHTML = `{an unknown error occurred while fetching the contact email}`;
    }
}

window.showContactEmail = showContactEmail;

function toggleHidden(className: string, hidden: boolean): void {
    const elements = document.getElementsByClassName(className);
    for (let i = 0; i < elements.length; i++) {
        let element = elements.item(i);
        element.classList.toggle('delayed', hidden)
    }
}

window.toggleHidden = toggleHidden;
